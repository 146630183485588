import { makeStyles } from "@material-ui/core"
import { DEFAULT_LEFT_PADDING, DEFAULT_RIGHT_PADDING, MOBILE_LEFT_PADDING, MOBILE_RIGHT_PADDING, WINDOW_MAX_WIDTH } from "src/layout/Layout.styles";

export const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#FFFFFF',
        color: 'white',
        [theme.breakpoints.down('md')]: {
            backgroundColor: '#F9F9FB',
        }
    },
    container: {
        margin: '0 auto',
        paddingTop: 0,
        backgroundColor: theme.palette.primary.main,
        display: 'flex',
        justifyContent: 'center',
    },
    banner: {
        position: 'relative',
        overflow: 'hidden',
        padding: '88px 0',
        maxWidth: WINDOW_MAX_WIDTH,
        paddingLeft: DEFAULT_LEFT_PADDING,
        paddingRight: DEFAULT_RIGHT_PADDING,
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down('md')]: {
            paddingLeft: MOBILE_LEFT_PADDING,
            paddingRight: MOBILE_LEFT_PADDING,
        }
    },
    textsContainer: {
        position: 'relative',
        maxWidth: '60%',
        [theme.breakpoints.down('md')]: {
            maxWidth: '100%'
        }
    },
    title: {
        color: 'inherit',
        fontSize: 48,
        fontWeight: 700,
        lineHeight: '50px',
        letterSpacing: '0.18px',
        '& span': {
            color: theme.palette.secondary.main
        },
        [theme.breakpoints.down('md')]: {
            lineHeight: '39px',
            fontSize: 28,
            padding: 24,
            marginBottom: 0,
            textAlign: 'center'
        }
    },
    description: {
        color: 'inherit',
        fontSize: 18,
        fontWeight: 450,
        [theme.breakpoints.down('md')]: {
            textAlign: 'center'
        }
    },
    imageContainer: {
        // marginLeft: 141,
        // [theme.breakpoints.down('md')]: {
        //     display: 'none',
        // }
    },
    buttonContainer: {
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            justifyContent: 'center',
        }
    },
    button: {
        backgroundColor: 'white',
        color: theme.palette.primary.main,
        fontWeight: 700,
        maxWidth: '100%',
        width: 227,
        minHeight: 48,
        '&:hover': {
            backgroundColor: 'white !important',
            color: theme.palette.primary.main + ' !important',
        },
        [theme.breakpoints.down('md')]: {
            marginBottom: 24
        }
    }
}))