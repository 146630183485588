import { makeStyles } from "@material-ui/core"
import { DEFAULT_LEFT_PADDING, DEFAULT_RIGHT_PADDING, MOBILE_LEFT_PADDING, WINDOW_MAX_WIDTH } from "src/layout/Layout.styles";

export const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: 'white',
        color: 'white',
    },
    container: {
        margin: '0 auto',
        maxWidth: WINDOW_MAX_WIDTH,
        paddingLeft: DEFAULT_LEFT_PADDING,
        paddingRight: DEFAULT_RIGHT_PADDING,
        paddingTop: 66,
        paddingBottom: 71,
        [theme.breakpoints.down('md')]: {
            position: 'relative',
            paddingLeft: MOBILE_LEFT_PADDING,
            paddingRight: MOBILE_LEFT_PADDING,
        }
    },
    title: {
        color: theme.palette.primary.main,
        fontSize: 40,
        fontWeight: 700,
        lineHeight: '40px',
        letterSpacing: '0.18px',
        textAlign: 'center',
        marginBottom: 40,
        [theme.breakpoints.down('md')]: {
        }
    },
    process: {
        padding: '32px 16px 60px',
        flexDirection: 'column',
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
            minHeight: 'auto',
            padding: 16
        }
    },
    processImageContainer: {
        display: 'flex',
        justifyContent: 'center',
        '& svg': {
            height: 40,
            width: 40,
        },
    },
    processTitle: {
        color: theme.palette.tertiary.main,
        fontSize: 20,
        fontWeight: 700,
        lineHeight: '22px',
        letterSpacing: '0.25px',
        [theme.breakpoints.down('md')]: {
            lineHeight: '18px',
        }
    },
    processDescription: {
        color: theme.palette.tertiary.main,
        fontSize: 16,
        fontWeight: 400,
        lineHeight: '22px',
        letterSpacing: '0.25px',
        [theme.breakpoints.down('md')]: {
            lineHeight: '18px',
        }
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('md')]: {
            paddingTop: 32
        }
    },
    button: {
        width: 272,
        maxWidth: '100%',
    },
    link: {
        color: `${theme.palette.primary.main} !important`,
        textDecoration: 'none',
        '&:visited': {
            color: `${theme.palette.primary.main} !important`,
        }
    }
}))