import React from "react"
import { Grid, Typography } from "@material-ui/core"
import { useStyles } from "./OurProcess.styles"
import { Button, Link, Title } from "src/components"
import { processItems } from "./OurProcess.items"

export function OurProcess() {
    const classes = useStyles()

    return (
        <section className={classes.root}>
            <Grid container className={classes.container}>

                <Grid item xs={12}>
                    <Title title="Tu hipoteca en 3 pasos" />
                </Grid>

                <Grid item xs={12}>
                    <Grid
                        container
                        direction="row"
                        spacing={2}
                    >
                        {processItems.map(({ icon: Icon, title, description }) => (
                            <Grid key={title} item xs={12} lg={4}>
                                <Grid container spacing={2} className={classes.process}>
                                    <Grid item xs={12} className={classes.processImageContainer}>
                                        <Icon />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography className={classes.processTitle}>
                                                    {title}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography className={classes.processDescription}>
                                                    {description}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>

                <Grid item xs={12} className={classes.buttonContainer}>
                    <Link
                        to='/simulador-de-credito-hipotecario'
                        className={classes.link}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            className={classes.button}
                        >
                            Simula tu crédito
                        </Button>
                    </Link>
                </Grid>

            </Grid>
        </section>
    )
}