import React from 'react'
import { Skeleton } from "@material-ui/lab";
import { Card } from '@material-ui/core';
import { useStyles } from "./styles";
import clsx from "clsx";

interface LoadingCardProps {
    className?: string;
}

export const LoadingCard = ({ className }: LoadingCardProps) => {
    const classes = useStyles()
    return (
        <Card elevation={0} className={clsx(classes.card, className)}>
            <Skeleton variant="rect" height={32} className={classes.skeleton} style={{ marginBottom: 32 }} />
            <Skeleton variant="rect" width={'40%'} height={16} className={classes.skeleton} />
            <Skeleton variant="rect" height={56} className={classes.skeleton} />
            <Skeleton variant="rect" width={'30%'} height={16} className={classes.skeleton} />
            <Skeleton variant="rect" height={56} className={classes.skeleton} />
            <Skeleton variant="rect" width={'60%'} height={16} className={classes.skeleton} />
            <Skeleton variant="rect" height={56} className={classes.skeleton} />
            <Skeleton variant="rect" height={48} className={classes.skeleton} style={{ marginTop: 32, borderRadius: 25 }} />
        </Card>
    )
}