import React from "react"
import { useStyles } from "./Requirements.styles"
import { Title } from "src/components/Title"
import { Grid, Typography } from "@material-ui/core"
// @ts-ignore  
import RequirementsImg from "./assets/requirements.png"
import { requirementsItems } from "./Requirements.items"
import { Link } from "src/components/Link"
import { useIsMobile } from "src/hooks"

export function Requirements() {
    const classes = useStyles()
    const isMobile = useIsMobile()

    return (
        <section className={classes.root}>
            <Grid container className={classes.container}>

                <Grid container spacing={isMobile ? 6 : 8}>
                    <Grid item xs={12}>
                        <Title title="Requisitos para obtener tu crédito" />
                    </Grid>

                    <Grid item xs={12} md={5}>
                        <img
                            src={RequirementsImg}
                            alt="Requisitos"
                            className={classes.requirementsImg}
                            loading="lazy"
                        />
                    </Grid>

                    <Grid item xs={12} md={7}>
                        <Grid container spacing={3}>
                            {requirementsItems.map(({ icon: Icon, title, description }) => (
                                <Grid item xs={12} key={title}>
                                    <Grid container className={classes.requirement}>
                                        <div className={classes.iconContainer}>
                                            <Icon />
                                        </div>
                                        <div className={classes.textsContainer}>
                                            <Typography className={classes.requirementTitle}> {title} </Typography>
                                            <Typography className={classes.requirementDescription}> {description} </Typography>
                                        </div>
                                    </Grid>
                                </Grid>
                            ))}
                            <Grid item xs={12}>
                                <Link
                                    to="https://cdn.prod.website-files.com/64b5bee1a430f0ba47dc7d26/66d0a1bbfa0fd664072f2c8e_FOLLETO%20INFORMATIVO%20Y%20DE%20COMISIONES%20DE%20CR%C3%89DITO%20HIPOTECARIO%20BANCOPPEL.pdf"
                                    className={classes.link}
                                    target="_blank"

                                >
                                    Conoce todos los requisitos
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </section>
    )
}