import { makeStyles } from "@material-ui/core"
import zIndex from "@material-ui/core/styles/zIndex";
import { DEFAULT_LEFT_PADDING, DEFAULT_RIGHT_PADDING, MOBILE_LEFT_PADDING, MOBILE_RIGHT_PADDING, WINDOW_MAX_WIDTH } from "src/layout/Layout.styles";

export const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative'
    },
    container: {
        margin: '0 auto',
        maxWidth: WINDOW_MAX_WIDTH,
        paddingLeft: DEFAULT_LEFT_PADDING,
        paddingRight: DEFAULT_RIGHT_PADDING,
        paddingTop: 77,
        paddingBottom: 77,
        [theme.breakpoints.down('md')]: {
            paddingLeft: MOBILE_LEFT_PADDING,
            paddingRight: MOBILE_RIGHT_PADDING,
            paddingTop: 33,
            paddingBottom: 33,
        }
    },
    rootBackgroundsContainer: {
        height: '100%',
        left: 0,
        top: 0,
        position: 'absolute',
        pointerEvents: 'none',
        width: '100%',
    },
    rootBackgroundImage: {
        left: 0,
        top: 0,
        position: 'absolute',
        zIndex: -1,
        '&.right': {
            bottom: 0,
            left: 'unset',
            right: 0,
            top: 'unset',
        }
    },
    title: {
        color: theme.palette.primary.main,
        fontSize: 48,
        fontWeight: 700,
        lineHeight: '54px',
        letterSpacing: '0.18px',
        textAlign: 'left',
        paddingBottom: 32,
        '&:after': {
            content: '""',
            display: 'block',
            width: '67px',
            height: '10px',
            backgroundColor: theme.palette.secondary.main,
            marginTop: 24,
        },
        [theme.breakpoints.down('md')]: {
            fontSize: 36,
            fontWeight: 700,
            lineHeight: '40px',
            letterSpacing: '0.18px',
            textAlign: 'center',
            paddingBottom: 40,
            paddingTop: 0,
            '&:after': {
                display: 'none',
            },
        }
    },
    paper: {
        backgroundColor: 'transparent',
        marginBottom: 48,
        [theme.breakpoints.down('md')]: {
            marginBottom: 0,
            padding: 0,
        }
    },
    paperTitle: {
        color: theme.palette.tertiary.main,
        fontSize: 24,
        fontWeight: 400,
        lineHeight: '28px',
        letterSpacing: '0.15px',
        marginBottom: 32,
        textAlign: 'left',
        [theme.breakpoints.down('md')]: {
            fontSize: 22,
            fontWeight: 400,
            lineHeight: '22px',
            letterSpacing: '0.15px',
            marginBottom: 32,
            textAlign: 'center',
        }
    },
    paperImageContainer: {
        textAlign: 'left',
        '& img, & svg': {
            marginBottom: 24,
        }
    },
    primaryColor: {
        color: theme.palette.primary.main,
    },
    listContainer: {
        textAlign: 'left',
        [theme.breakpoints.down('md')]: {
            textAlign: 'center',
        }
    },
    list: {
        display: 'inline-block',
        textAlign: 'left',
        textDecoration: 'none',
        listStyle: 'none',
        padding: '0 0',
        margin: 0,
        marginBottom: 24,
        fontSize: 16,
        fontWeight: 400,
        lineHeight: '24px',
        letterSpacing: '0.25px',
        '& li': {
            alignItems: 'center',
            display: 'flex',
            padding: '0px 0px 16px',
            '& svg': {
                marginRight: 8,
                '& path': {
                    fill: theme.palette.primary.main,
                }
            },
        },
        '& li:last-child': {
            paddingBottom: 0,
        },
        [theme.breakpoints.down('md')]: {
            fontSize: 14,
            fontWeight: 400,
            lineHeight: '24px',
            letterSpacing: '0.25px',
            padding: 0,
            margin: 0,
            textAlign: 'center',
        }
    },
    requestButton: {
        height: 36,
        maxWidth: '100%',
        width: 277,

        fontSize: 16,
        fontWeight: 700,
        lineHeight: '24px',
        letterSpacing: '0.15px',

        '&:has(> a)': {
            maxWidth: '100%',
        }
    },
    link: {
        fontSize: 16,
        fontWeight: 400,
        lineHeight: '24px',
        letterSpacing: '0.15px',

        '&:has(> a)': {
            maxWidth: '100%',
        }
    },
    homeFamilyImageContainer: {
        minHeight: 460,
        position: 'relative',
        '& svg': {
            position: 'absolute',
            zIndex: -1,
            left: -260
        },
        [theme.breakpoints.down('md')]: {
            textAlign: 'center',
            minHeight: 'auto',
            '& svg': {
                position: 'initial',
                zIndex: 0,
                left: 0,
                maxWidth: '100%',
                height: 'auto',
            },
        }
    }
}))