import TasaIcon from "./assets/tasa.svg"
import MensualidadIcon from "./assets/mensualidad.svg"
import AsesorIcon from "./assets/asesor.svg"
import IngresosIcon from "./assets/ingresos.svg"
import CuidaPlanetaIcon from "./assets/cuida_planeta.svg"
import SegurosIcon from "./assets/seguros.svg"

export const advantagesItems: {
    icon: any,
    title: string
    description: string
}[] = [
        {
            icon: TasaIcon,
            title: 'Tasa desde 9.90% ¹',
            description: 'Tasa anual fija y competitiva para tu tranquilidad financiera.'
        },
        {
            icon: MensualidadIcon,
            title: 'Mensualidad Comodín ²',
            description: 'Flexibilidad para imprevistos: salta un pago si eres puntual.'
        },
        {
            icon: AsesorIcon,
            title: 'Asesor personalizado',
            description: 'Asesor único que te acompaña por teléfono o videollamada'
        },
        {
            icon: IngresosIcon,
            title: 'Suma Ingresos',
            description: 'Con tu pareja, familia o amigos y obtén un monto mayor.'
        },
        // {
        //     icon: CuidaPlanetaIcon,
        //     title: 'Tu hipoteca cuida el planeta',
        //     description: 'Neutralizaremos la huella de carbono de tu inmueble a través de diversas acciones a favor del medio ambiente.'
        // },
        {
            icon: SegurosIcon,
            title: 'Con los mejores seguros',
            description: 'De vida y daños incluidos en tu crédito.'
        },
    ]