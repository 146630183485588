import { makeStyles } from "@material-ui/core"
import { DEFAULT_LEFT_PADDING, DEFAULT_RIGHT_PADDING, WINDOW_MAX_WIDTH, MOBILE_LEFT_PADDING, MOBILE_RIGHT_PADDING } from "src/layout/Layout.styles";

export const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#F9F9FB',
    },
    center: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    container: {
        margin: '0 auto',
        maxWidth: WINDOW_MAX_WIDTH,
        paddingLeft: DEFAULT_LEFT_PADDING,
        paddingRight: DEFAULT_RIGHT_PADDING,
        paddingTop: 62,
        paddingBottom: 62,
        [theme.breakpoints.down('md')]: {
            paddingLeft: MOBILE_LEFT_PADDING,
            paddingRight: MOBILE_RIGHT_PADDING,
            paddingTop: 31,
            paddingBottom: 31,
        }
    },
    title: {
        color: theme.palette.tertiary.main,
        fontSize: 40,
        fontWeight: 700,
        lineHeight: '40px',
        letterSpacing: '0.18px',
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
            fontSize: 36,
            fontWeight: 700,
            lineHeight: '40px',
            letterSpacing: '0.18px',
        }
    },
    paper: {
        backgroundColor: '#FFFFFF',
        borderRadius: 16,
        boxShadow: '0px 3px 20px #EDEDF0',
        padding: 24,
        height: '100%',
        [theme.breakpoints.down('md')]: {
            marginBottom: 0,
            minHeight: 'auto',
        },
    },
    paperTitle: {
        color: 'black',
        fontSize: 20,
        fontWeight: 700,
        lineHeight: '24px',
        letterSpacing: '0.15px',
        [theme.breakpoints.down('md')]: {
            fontSize: 20,
            fontWeight: 700,
            lineHeight: '24px',
            letterSpacing: '0.15px',
        }
    },
    paperDescription: {
        fontSize: 14,
    },
    paperImageContainer: {
        '& svg': {
            [theme.breakpoints.down('md')]: {
                height: 64,
                width: 64,
            }
        },
        [theme.breakpoints.down('md')]: {
            top: -97,
        },
    },
    livingRoomImg: {
        maxWidth: '100%'
    },
    text: {
        fontSize: 12,
    },
    divider: {
        backgroundColor: '#FBE65E',
        height: 4,
        width: 67,
        marginTop: 24,
        [theme.breakpoints.down('md')]: {
            display: 'none',
        }
    }
}))