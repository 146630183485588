import ApproveIcon from "./assets/approve.svg"
import OfferIcon from "./assets/offer.svg"
import FormalizeIcon from "./assets/formalize.svg"

export const processItems: {
    icon: any
    title: string
    description: string
}[] = [
        {
            icon: ApproveIcon,
            title: '1. Preapruébate en línea',
            description: 'En solo 5 minutos descubre si eres candidato y cuánto podrías obtener para tu crédito'
        },
        {
            icon: OfferIcon,
            title: '2. Recibe tu oferta',
            description: 'Completa tu solicitud en línea y asegura tu aprobación en menos de 72 horas.'
        },
        {
            icon: FormalizeIcon,
            title: '3. Formaliza tu crédito',
            description: 'Utiliza tu oferta desde el avalúo hasta la notaría y firma tu crédito'
        },
    ]