import { makeStyles } from "@material-ui/core"
import { DEFAULT_LEFT_PADDING, DEFAULT_RIGHT_PADDING, MOBILE_LEFT_PADDING, WINDOW_MAX_WIDTH } from "src/layout/Layout.styles";

export const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#fff',
    },
    container: {
        margin: '0 auto',
        maxWidth: WINDOW_MAX_WIDTH,
        paddingLeft: DEFAULT_LEFT_PADDING,
        paddingRight: DEFAULT_RIGHT_PADDING,
        paddingTop: 66,
        paddingBottom: 71,
        [theme.breakpoints.down('md')]: {
            position: 'relative',
            paddingLeft: MOBILE_LEFT_PADDING,
            paddingRight: MOBILE_LEFT_PADDING,
        }
    },
    featureTitle: {
        color: theme.palette.tertiary.main,
        fontSize: 20,
        fontWeight: 700,
        lineHeight: '22px',
        letterSpacing: '0.25px',
        textAlign: 'center',
        margin: 0,
        [theme.breakpoints.down('md')]: {
            lineHeight: '18px',
        }
    },
    link: {
        color: `${theme.palette.primary.main} !important`,
        textDecoration: 'none',
        fontWeight: 500,
        fontSize: 16,
        '&:visited': {
            color: `${theme.palette.primary.main} !important`,
        }
    },
    requirementsImg: {
        maxWidth: '100%'
    },
    requirement: {
        padding: '16px 24px',
        borderRadius: 16,
        backgroundColor: '#fff',
        display: 'flex',
        gap: 16,
    },
    textsContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        minHeight: 52
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    feature: {
        borderRadius: 16,
        border: '1px solid #CFD5E2',
        padding: 24,
        minHeight: 148,
        gap: 16
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    button: {
        width: 272,
        maxWidth: '100%',
    },
    text: {
        fontSize: 14,
        fontWeight: 450,
    }
}))