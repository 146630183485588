import { makeStyles } from "@material-ui/core"
import { DEFAULT_LEFT_PADDING, DEFAULT_RIGHT_PADDING, MOBILE_LEFT_PADDING, WINDOW_MAX_WIDTH } from "src/layout/Layout.styles";

export const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#fff',
    },
    container: {
        margin: '0 auto',
        maxWidth: WINDOW_MAX_WIDTH,
        paddingLeft: DEFAULT_LEFT_PADDING,
        paddingRight: DEFAULT_RIGHT_PADDING,
        paddingTop: 66,
        paddingBottom: 71,
        [theme.breakpoints.down('md')]: {
            position: 'relative',
            paddingLeft: MOBILE_LEFT_PADDING,
            paddingRight: MOBILE_LEFT_PADDING,
        }
    },
    card: {
        padding: 24,
        borderRadius: 10,
        border: '1px solid #CFD5E2',
        display: 'flex',
        gap: 40
    },
    cardImgContainer: {
        [theme.breakpoints.down('md')]: {
            width: '100%'
        }
    },
    cardImg: {
        maxWidth: '100%',
        width: 196,
        [theme.breakpoints.down('md')]: {
            width: '100%'
        }
    },
    cardsTextContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: 206,
        gap: 24,
        maxWidth: '60%',
        [theme.breakpoints.down('md')]: {
            maxWidth: '100%',
            textAlign: 'center',
        }
    },
    link: {
        color: `${theme.palette.primary.main} !important`,
        textDecoration: 'none',
        fontWeight: 500,
        fontSize: 16,
        '&:visited': {
            color: `${theme.palette.primary.main} !important`,
        }
    },
    text: {
        fontSize: 16,
        fontWeight: 400,
        margin: 0
    },
    chipsContainer: {
        width: '100%',
        display: 'flex',
        gap: 16,
        paddingTop: 16,
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        }
    },
    chip: {
        fontSize: 14,
        fontWeight: 600,
        backgroundColor: '#fff',
        padding: '8px 12px',
        height: 40,
        borderRadius: 100,
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        width: 'max-content',
    }
}))