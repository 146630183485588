import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles((theme) => ({
    tabs: {
        '& .MuiTabs-flexContainer': {
            gap: 16
        }
    },
    tab: {
        fontSize: 16,
        fontWeight: 450,
        textTransform: 'none',
        color: theme.palette.tertiary.main,
        borderBottom: '2px solid #CFD5E2',
        [theme.breakpoints.down('md')]: {
            display: 'none'
        },
    },
    mobileTab: {
        width: '100%',
        padding: '8px 0',
        borderBottom: '2px solid #CFD5E2',
        transition: 'color 0.3s, border-bottom 0.3s',
        height: 48,
        fontSize: 16,
        [theme.breakpoints.up('md')]: {
            display: 'none'
        },
    },
    selectedTab: {
        color: theme.palette.primary.main,
        borderBottom: `2px solid ${theme.palette.primary.main}`,
        transition: 'color 0.3s, border-bottom 0.3s',
    },
    title: {
        fontSize: 24,
        fontWeight: 700,
    },
    content: {
        '& h3': {
            fontSize: 24,
            fontWeight: 700,
        },
        '& h5': {
            fontSize: 16,
            fontWeight: 700,
            marginBottom: 0
        },
        '& p': {
            fontSize: 16,
            fontWeight: 400,
            lineHeight: '22px',
            letterSpacing: '0.25px',
            color: theme.palette.tertiary.main,
        },
        '& strong': {
            fontSize: 16,
            fontWeight: 700,
            lineHeight: '22px',
            letterSpacing: '0.25px',
            color: theme.palette.tertiary.main,
        },
        '& li': {
            fontSize: 16,
            fontWeight: 400,
            lineHeight: '22px',
            letterSpacing: '0.25px',
            color: theme.palette.tertiary.main,
            marginBottom: 4,
            '& p': {
                margin: '0 0 8px !important'
            }
        },
        '& a': {
            color: `${theme.palette.primary.main} !important`,
            textDecoration: 'none',
            fontWeight: 500,
            fontSize: 16,
            '&:visited': {
                color: `${theme.palette.primary.main} !important`,
            }
        }
    },
    mobileTabsContainer: {
        width: '100%',
    }
}))