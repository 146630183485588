import { Grid, Tab, Tabs } from "@material-ui/core"
import React from "react"
import { getInformationTabsItems } from "./Tabs.items"
import { useStyles } from "./Tabs.styles"
import { useIsMobile } from "src/hooks"
import clsx from "clsx"

export function InformationTabs() {
    const classes = useStyles()
    const isMobile = useIsMobile()
    const [value, setValue] = React.useState(0)
    const informationTabsItems = getInformationTabsItems(classes)

    return (
        <Grid container>
            {!isMobile && (
                <Tabs
                    value={value}
                    onChange={(_, newValue) => setValue(newValue)}
                    indicatorColor="primary"
                    textColor="primary"
                    className={classes.tabs}
                >
                    {informationTabsItems.map(({ title }) => (
                        <Tab
                            key={title}
                            label={title}
                            className={classes.tab}
                        />
                    ))}
                </Tabs>
            )}

            {isMobile && (
                <div className={classes.mobileTabsContainer}>
                    {informationTabsItems.map(({ title }, index) => (
                        <div
                            key={title}
                            onClick={() => setValue(index)}
                            className={clsx(classes.mobileTab, {
                                [classes.selectedTab]: value === index
                            })}
                        >
                            {title}
                        </div>
                    ))}
                </div>
            )}

            {informationTabsItems[value].component}

        </Grid>
    )
}