import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#FFFFFF',
        borderRadius: 8,
        boxShadow: '0px 3px 20px #EDEDF0',
        padding: '48px 16px 36px',
        position: 'relative',
    },
    imageContainer: {
        top: -44,
        left: 0,
        textAlign: 'center',
        position: 'absolute',
        width: '100%',
        '& svg': {
            margin: '0 auto',
        }
    },
    name: {
        color: theme.palette.primary.main,
        marginBottom: 4,
        textAlign: 'center',

        fontSize: 16,
        fontWeight: 700,
        lineHeight: '24px',
        letterSpacing: '0.15px',
    },
    testimony: {
        textAlign: 'center',

        fontSize: 16,
        fontWeight: 400,
        lineHeight: '24px',
        letterSpacing: '0.5px',
    },
}))