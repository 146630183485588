import React, { useState } from 'react'
import { Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from "@material-ui/core";
import { LoadableQuotationCard } from "../QuotationCard";
import { LoadingCard } from "../QuotationCard/LoadingCard";
import { useStyles } from './Hero.styles';
import clsx from 'clsx';
import { Check } from '@material-ui/icons';

interface HeroProps {
	productImplementation?: 'adquisicion-de-vivienda' | 'mejora-de-hipoteca'
	title?: string,
	description?: string
	showProductSelectInput?: boolean
	segment?: {
		simulateLabel?: string
		requestApplicationLabel?: string
	}
}

export function Hero({
	productImplementation,
	title,
	description,
	showProductSelectInput,
	segment
}: HeroProps) {
	const classes = useStyles()
	const [isLoadingAbacus, setIsLoadingAbacus] = useState(true)

	return (
		<section className={classes.root}>
			<Grid
				container
				className={classes.container}
			>
				<Grid
					item
					md={7}
					xs={12}
					style={{
						alignSelf: 'center'
					}}
				>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Typography className={classes.subtitle}>
								Crédito hipotecario
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography
								className={classes.title}
								component="h1"
								dangerouslySetInnerHTML={{
									__html: title
										? title
										: `Simula, preapuébate y conoce tu tasa <br /> en 5 minutos`
								}}
							/>
						</Grid>
						<Grid item xs={12}>
							<Typography className={classes.subtitle}>
								Digital, confiable y claro
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<div className={classes.divider} />
						</Grid>
						<Grid item xs={12}>
							<List>
								<ListItem className={classes.item}>
									<ListItemIcon>
										<Check color="primary" />
									</ListItemIcon>
									<ListItemText
										primary="Tasa desde el 9.90%"
										className={classes.listItem}
									/>
								</ListItem>
								<ListItem className={classes.item}>
									<ListItemIcon>
										<Check color="primary" />
									</ListItemIcon>
									<ListItemText
										primary="Ingreso mínimo a partir de $8,000 pesos"
										className={classes.listItem}
									/>
								</ListItem>
								<ListItem className={classes.item}>
									<ListItemIcon>
										<Check color="primary" />
									</ListItemIcon>
									<ListItemText
										primary="Asesoría personalizada durante todo el proceso "
										className={classes.listItem}
									/>
								</ListItem>
							</List>
						</Grid>
					</Grid>
				</Grid>
				<Grid
					item
					md={5}
					xs={12}
					className={classes.simulatorContainer}
				>
					<LoadingCard
						className={clsx(classes.simulatorLoader, {
							[classes.displayNone]: !isLoadingAbacus
						})}
					/>
					<div className={clsx(classes.simulator, {
						[classes.displayNone]: isLoadingAbacus,
						['multiProduct']: Boolean(showProductSelectInput),
					})} >
						<LoadableQuotationCard
							segment={segment}
							showProductSelectInput={showProductSelectInput}
							productImplementation={productImplementation}
							enableDownloadAmortTable
							handleInputsLoaded={(isLoaded: boolean) => setIsLoadingAbacus(!isLoaded)}
						/>
					</div>
				</Grid>
			</Grid>
		</section>
	)
}