import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
	card: {
		background: 'white',
		boxShadow: '0px 0px 17.6px 0px rgba(30, 51, 91, 0.07);',
		borderRadius: 30,
		width: 407,
		minHeight: 474,
		padding: 40,
		position: 'relative',
		[theme.breakpoints.down('md')]: {
			width: "100%",
		},
		[theme.breakpoints.down('sm')]: {
			maxWidth: '100%',
			padding: '24px 16px'
		},
		transition: theme.transitions.create('height', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	skeleton: {
		marginBottom: 12
	}
}))