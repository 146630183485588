export const chipItems: {
    label: string
    url: string
}[] = [
        {
            label: 'Folleto informativo',
            url: 'https://cdn.prod.website-files.com/64b5bee1a430f0ba47dc7d26/67056121734f79b06df5aaa1_FOLLETO%20INFORMATIVO%20PAGO%20FIJO%20Y%20PAGO%20LIGERO.pdf',
        },
        {
            label: 'Comisiones',
            url: 'https://cdn.prod.website-files.com/64b5bee1a430f0ba47dc7d26/67056419c73a4e0323871038_Tabla%20de%20Tasas%20y%20Comisiones%20Cr%C3%A9dito%20Hipotecario.pdf',
        },
        {
            label: 'Contrato adhesión',
            url: 'https://hipoteca.bancoppel.com/avisos-legales#contratos',
        },
        {
            label: 'Domiciliación',
            url: 'https://hipoteca.bancoppel.com/avisos-legales#domiciliacion',
        },
    ]