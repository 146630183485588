import React from "react"
import type { PageProps } from "gatsby"
import { Layout } from "src/layout"
import {
	OurAdvantages,
	OurProcess,
	Banner,
	Hero,
	Requirements,
	Features,
	Information
} from "src/components/pages/Home"

const IndexPage: React.FC<PageProps> = ({ location }: PageProps) => {
	return (
		<Layout location={location}>

			<Hero productImplementation="adquisicion-de-vivienda" />

			<OurAdvantages />

			<OurProcess />

			<Requirements />

			<Features />

			<Banner />

			<Information />

		</Layout>
	)
}

export default IndexPage