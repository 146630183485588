import { Fade } from "@material-ui/core"
import React from "react"

export const getInformationTabsItems = (classes: any): {
    title: string
    component: React.ReactNode
}[] => [
        {
            title: 'Recomendaciones y avisos',
            component: (
                <Fade in>
                    <div className={classes.content}>
                        <h3> Recomendaciones y avisos importantes </h3>
                        <ul>
                            <li> Para el caso de los créditos con tasa fija no existe riesgo por inflación o tipo de cambio. </li>
                            <li> Contratar créditos que excedan tu capacidad de pago afecta tu historial crediticio. </li>
                            <li> El pago oportuno que realices de tus créditos en las fechas pactadas con el Banco te permitirá mantener un historial crediticio satisfactorio.</li>
                            <li> En caso de que la fecha límite de pago corresponda a un día inhábil bancario, podrás realizar dicho pago el siguiente día hábil. </li>
                            <li> Incumplir con tus obligaciones te puede generar comisiones. </li>
                        </ul>
                        <p> Es tu derecho solicitar la oferta vinculante para comparar distintas opciones de crédito. </p>

                        <h5> UNE </h5>
                        <p style={{ marginTop: 0 }}>
                            <strong> BanCoppel S.A. Institución de Banca Múltiple </strong> recibe las consultas, reclamaciones o aclaraciones, en su Unidad Especializada de Atención a Usuarios, ubicada
                            en Insurgentes Sur 553 - Piso 6, Col. Escandón II Sección, C.P. 11800, Alcaldía Miguel Hidalgo, Ciudad de México. y por correo
                            electrónico <a href="mailto:une@bancoppel.com"> une@bancoppel.com </a>o al teléfono (55) 5278 0000 Ext 510 196, así como en cualquiera de sus sucursales u oficinas.
                        </p>

                        <h5> CONDUSEF </h5>
                        <p style={{ marginTop: 0 }}>
                            En el caso de no obtener una respuesta satisfactoria, podrás acudir a la Comisión Nacional para la Protección y Defensa de los Usuarios de Servicios
                            Financieros en <a href="https://www.condusef.gob.mx/" target="_blank" rel="noreferrer"> www.condusef.gob.mx </a> , o a los teléfonos (55) 5340 0999 y 800 999 8080.
                        </p>

                    </div>
                </Fade>
            )
        },
        {
            title: 'Transparencia',
            component: (
                <Fade in>
                    <div className={classes.content}>
                        <h3> Transparencia </h3>
                        <ul>
                            <li> Es tu derecho solicitar la oferta vinculante para comparar distintas opciones de crédito. </li>
                            <li> La aprobación final depende de la evaluación y cumplimiento de las políticas de crédito de BanCoppel. </li>
                            <li> Incumplir tus obligaciones te puede generar comisiones. </li>
                            <li> Al contratar el crédito hipotecario las personas físicas pueden obtener un beneficio fiscal deduciendo los intereses reales. </li>
                            <li> El cliente podrá contratar libremente los seguros requeridos con la Aseguradora de su preferencia. </li>
                            <li>
                                Garante Hipotecario: Es aquella persona que constituye un gravamen real sobre un bien inmueble en favor del banco o cualquier acreedor y da a
                                este último el derecho de, en caso de incumplimiento por parte del acreditado, ser pagado con el valor del inmueble puesto en garantía.
                            </li>
                            <li>
                                Acreditado: Una persona acreditada es aquella que ha sido aprobada por el banco para recibir un préstamo o una línea de crédito y es el
                                principal obligado al pago de las obligaciones del crédito. Esto significa que el banco confía en su capacidad para devolver el dinero prestado según los términos acordados.
                            </li>
                            <li>
                                Coacreditado: Un coacreditado es una persona que comparte la responsabilidad del préstamo o la línea de crédito con el acreditado principal.
                                Ambos comparten la obligación de pagar el préstamo y son evaluados conjuntamente por el banco en función de su capacidad crediticia. Esto
                                puede mejorar las posibilidades de obtener una cantidad mayor de crédito o mejores condiciones de préstamo.
                            </li>
                            <li>
                                Obligado Solidario: el obligado solidario es aquella persona que se compromete a pagar la deuda completa del acreditado y, en su caso, del
                                coacreditado desde el momento de la firma del contrato de crédito y hasta el momento en el que se ha cumplido con el pago del monto total del crédito.
                            </li>
                        </ul>
                    </div>
                </Fade>
            )
        },
        {
            title: 'Tips de seguridad',
            component: (
                <Fade in>
                    <div className={classes.content}>
                        <h3> Tipos de seguridad </h3>
                        <p>
                            <strong> No compartas tu contraseña con nadie </strong> y protege tus cuentas. Cualquier mensaje o llamada que recibas solicitando estos datos, no es BanCoppel,
                            <strong> puede ser un fraude. </strong>
                        </p>
                        <a
                            href="https://www.bancoppel.com/acerca_bancoppel/tips.html?v=2"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Consultar más tips de seguridad
                        </a>
                    </div>
                </Fade>
            )
        },
        {
            title: 'Preguntas frecuentes',
            component: (
                <Fade in>
                    <div className={classes.content}>
                        <h3> Preguntas frecuentes </h3>
                        <ul>
                            <li>
                                <strong> ¿Qué es un crédito hipotecario? </strong>
                                <br />
                                <p>
                                    Es un préstamo a largo plazo destinado a financiar la compra de una casa o departamento. El inmueble queda como garantía de pago.
                                </p>
                            </li>
                            <li>
                                <strong> ¿Por qué contratar un crédito hipotecario? </strong>
                                <br />
                                <p>
                                    Una vivienda es el patrimonio más importante con el que puede contar cualquier familia y un crédito hipotecario es un gran aliado para adquirirla. El crédito se paga con una inversión inicial baja y posteriormente pagos mensuales. Si estás pensando en adquirir una casa y no puedes hacerlo de contado o no quieres descapitalizarte, un crédito hipotecario puede ser tu mejor opción.
                                </p>
                            </li>
                            <li>
                                <strong> ¿Qué debes considerar antes de tramitar tu crédito hipotecario? </strong>
                                <br />
                                <p>
                                    Es importante hacer un análisis de tu capacidad de pago para no afectar negativamente tu historial de crédito. Incumplir tus obligaciones te puede generar comisiones.
                                </p>
                            </li>
                            <li>
                                <strong> ¿Cuáles son los principales gastos al contratar un crédito hipotecario? </strong>
                                <br />
                                <p>
                                    Los principales gastos asociados al crédito son el avalúo y la comisión por apertura. Los gastos asociados a la compra de una casa o departamento son el enganche, los honorarios notariales y los impuestos.
                                </p>
                            </li>
                            <li>
                                <strong> ¿Cuáles son los plazos del Crédito Hipotecario BanCoppel? </strong>
                                <br />
                                <p>
                                    10, 15 y 20 años.
                                </p>
                            </li>
                            <li>
                                <strong> ¿Puedo liquidar mi crédito anticipadamente? </strong>
                                <br />
                                <p>
                                    Siempre puedes liquidar tu crédito sin pagar penalización alguna.
                                </p>
                            </li>
                            <li>
                                <strong> ¿Qué es el CAT? </strong>
                                <br />
                                <p>
                                    Es el Costo Anual Total, para efectos informativos, anualiza la totalidad de los costos directos inherentes al crédito incluyendo seguros y excluyendo las contribuciones federales y locales, así como los costos correspondientes a trámites y servicios prestados por terceros.
                                </p>
                            </li>
                            <li>
                                <strong> Si soy derechohabiente Infonavit, ¿qué esquemas de financiamiento manejan? </strong>
                                <br />
                                <p>
                                    Cofinavit: Crédito Hipotecario BanCoppel complementa el crédito que te otorgue el Infonavit para financiar hasta el 95% del valor del inmueble a financiar. <br />
                                    Apoyo Infonavit: El ahorro de tu Subcuenta de Vivienda queda en garantía del Crédito Hipotecario BanCoppel. En caso de pérdida del empleo, el saldo de la subcuenta de vivienda se utilizará para apoyarte a cubrir las mensualidades.
                                </p>
                            </li>
                            <li>
                                <strong> ¿Es recomendable firmar un Contrato de Compraventa? </strong>
                                <br />
                                <p>
                                    Te recomendamos que antes de firmar un contrato de compraventa tengas tu crédito hipotecario autorizado y que el plazo para firmar la operación conste del mayor tiempo posible ya que en ocasiones el trámite puede retrasarse por diferentes motivos.
                                </p>
                            </li>
                            <li>
                                <strong> ¿Es deducible el pago de mi crédito hipotecario? </strong>
                                <br />
                                <p>
                                    Puedes deducir la parte real de los intereses pagados de tu crédito hipotecario en tu declaración de impuestos sobre la renta. Para ello se aplicarán las disposiciones vigentes de la Secretaría de Hacienda y Crédito Público.
                                </p>
                            </li>
                        </ul>
                    </div>
                </Fade>
            )
        },
        {
            title: 'Medios de pago',
            component: (
                <Fade in>
                    <div className={classes.content}>
                        <h3>Medios de pago </h3>
                        <p> Puedes pagar tu crédito hipotecario mediante: </p>
                        <ul>
                            <li> Domiciliación a cuenta eje del cliente, donde se harán los cargos automáticos mensuales en la fecha de pago </li>
                            <li> Efectivo en ventanilla en sucursal BanCoppel </li>
                            <li> Transferencia electrónica de fondos </li>
                            <li> Cheque salvo buen cobro </li>
                        </ul>
                    </div>
                </Fade>
            )
        },
    ]