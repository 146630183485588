import React from "react"
import { Grid, Typography } from "@material-ui/core"
import { Button } from "src/components"
// @ts-ignore
import BannerImg from "src/assets/images/pages/home/banner.png"

import { useStyles } from "./Banner.styles"

export function Banner() {
    const classes = useStyles()

    return (
        <section className={classes.root}>
            <div className={classes.container}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    className={classes.banner}
                >
                    <div className={classes.imageContainer}>
                        <img
                            src={BannerImg}
                            alt="Simula tu crédito"
                            loading="lazy"
                        />
                    </div>
                    <div className={classes.textsContainer}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography className={classes.title}>
                                    Conoce cuánto te podemos prestar en <span> 5 minutos </span>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.description}>
                                    Con tasa fija desde 9.90%. Preapruébate y obtén un asesor exclusivo para tu solicitud de crédito digital, sin necesidad de ir a sucursal.
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.description}>
                                    ¡Es fácil y rápido!
                                </Typography>
                            </Grid>
                            <Grid item xs={12} className={classes.buttonContainer}>
                                <Button
                                    to="/simulador-de-credito-hipotecario"
                                    variant="contained"
                                    color="default"
                                    className={classes.button}
                                    segmentLabel="Home Solicitar Bottom"
                                >
                                    Simula tu crédito
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </div>
        </section>
    )
}