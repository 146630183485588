import { makeStyles } from "@material-ui/core"
import { DEFAULT_LEFT_PADDING, DEFAULT_RIGHT_PADDING, MOBILE_LEFT_PADDING, MOBILE_RIGHT_PADDING, WINDOW_MAX_WIDTH } from "src/layout/Layout.styles";

export const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#FFFFFF',
        [theme.breakpoints.down('md')]: {
        }
    },
    container: {
        margin: '0 auto',
        maxWidth: WINDOW_MAX_WIDTH,
        paddingLeft: DEFAULT_LEFT_PADDING,
        paddingRight: DEFAULT_RIGHT_PADDING,
        paddingTop: 71,
        paddingBottom: 68,
        [theme.breakpoints.down('md')]: {
            paddingLeft: MOBILE_LEFT_PADDING,
            paddingRight: MOBILE_RIGHT_PADDING,
        }
    },
    title: {
        color: theme.palette.primary.main,
        fontSize: 40,
        fontWeight: 700,
        lineHeight: '40px',
        letterSpacing: '0.18px',
        textAlign: 'center',
        marginBottom: 98,
        '&:after': {
            content: '""',
            display: 'block',
            width: '67px',
            height: '10px',
            backgroundColor: theme.palette.secondary.main,
            margin: '24px auto 0px',
        },
        [theme.breakpoints.down('md')]: {
        }
    },
    testimonyContainer: {
    },
    testimony: {
        marginBottom: 32,
        minHeight: 184,
        [theme.breakpoints.down(1441)]: {
            minHeight: 208,
        },
        [theme.breakpoints.down('md')]: {
            minHeight: 208,
        }
    },
    paginationContainer: {
        marginBottom: 48,
        [theme.breakpoints.down('md')]: {
            marginBottom: 17,
        }
    },
    slider: {
        marginBottom: 48,
        '& .slick-dots': {
            // bottom: -36,
            position: 'initial',
            '& li, & button, & button::before': {
                width: 8,
            },
            '& li': {
                margin: '0px 4px',
            },
            '& button': {
                padding: 0,
            },
            '& button::before': {
                color: theme.palette.primary.main,
                content: '"\\2022"',
                fontSize: 12,
            },
            '& .slick-active button::before': {
                color: theme.palette.primary.main,
            },
        },
        '& .slick-list': {
            margin: '0 -15px',
            overflowX: 'clip',
            overflowY: 'visible',
        },
        '& .slick-slide > div': {
            margin: '0 15px',
        },
        [theme.breakpoints.down('md')]: {
            marginBottom: 37,
        }
    },
}))