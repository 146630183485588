import React from "react"
import { useStyles } from "./Information.styles"
import { Chip, Grid, Typography } from "@material-ui/core"
import { Link, Title } from "src/components"
import { chipItems } from "./Information.items"
// @ts-ignore
import DudasImg from "./assets/dudas.png"
import { InformationTabs } from "./Tabs"

export function Information() {
    const classes = useStyles()

    return (
        <section id="information" className={classes.root}>
            <Grid container className={classes.container}>

                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Title title="Te acompañamos en todo momento" />
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container className={classes.card}>
                            <div className={classes.cardImgContainer}>
                                <img
                                    src={DudasImg}
                                    className={classes.cardImg}
                                    loading="lazy"
                                />
                            </div>
                            <div className={classes.cardsTextContainer}>
                                <Typography style={{ fontSize: 20, fontWeight: 700 }}> ¿Tienes dudas? </Typography>
                                <Typography className={classes.text}> Al preaprobarte, tendrás un asesor profesional que te acompañará en todo el proceso. </Typography>
                                <Link to="#faq" className={classes.link}>
                                    Ir a preguntas frecuentes
                                </Link>
                            </div>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <Typography className={classes.text}>
                            <strong> {'Tasa de interés anual fija desde 9.90% hasta 12.50%. CAT (COSTO ANUAL TOTAL) PROMEDIO 12.8% sin IVA (para fines informativos y de comparación)'} </strong>
                        </Typography>
                        <Typography className={classes.text}>
                            {'**Fecha de cálculo del CAT: 01 de octubre 2024. Fecha de vigencia de la oferta: del 01 de octubre 2024 al 31 de marzo 2025. Vigencia del cálculo del CAT no mayor a 6 meses.'}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container className={classes.card} id="faq">
                            <InformationTabs />
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography className={classes.text}>
                            Consulta el folleto de tu hipoteca BanCoppel para conocer más características del crédito, beneficios, requisitos, entre otros.
                        </Typography>
                        <div className={classes.chipsContainer}>
                            {chipItems.map(({ label, url }) => (
                                <Link
                                    key={label}
                                    to={url}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <div key={label} className={classes.chip}>
                                        <span> {label} </span>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </Grid>
                </Grid>

            </Grid>
        </section>
    )
}