import { makeStyles } from "@material-ui/core";
import { DEFAULT_LEFT_PADDING, DEFAULT_RIGHT_PADDING, MOBILE_LEFT_PADDING, MOBILE_RIGHT_PADDING, WINDOW_MAX_WIDTH } from "src/layout/Layout.styles";

export const useStyles = makeStyles((theme) => ({
	primaryColor: {
		color: theme.palette.primary.main,
	},
	displayNone: {
		display: 'none'
	},
	root: {
		backgroundColor: '#fff',
		// background: 'linear-gradient(0deg, white, #f1f4fa 100%)',
		[theme.breakpoints.down('md')]: {
			// background: 'linear-gradient(0deg, white, #f1f4fa 100%)',
		}
	},
	container: {
		margin: '0 auto',
		minHeight: 499,
		maxWidth: WINDOW_MAX_WIDTH,
		paddingLeft: DEFAULT_LEFT_PADDING,
		paddingRight: DEFAULT_RIGHT_PADDING,
		overflow: 'hidden',
		[theme.breakpoints.down('md')]: {
			position: 'relative',
			paddingLeft: MOBILE_LEFT_PADDING,
			paddingRight: MOBILE_RIGHT_PADDING,
		}
	},
	title: {
		color: theme.palette.tertiary.main,
		fontSize: 64,
		fontWeight: 700,
		marginBottom: 0,
		width: '100%',
		letterSpacing: '0.18px',
		lineHeight: '64px',
		[theme.breakpoints.down('md')]: {
			fontSize: 48,
			lineHeight: '48px',
			fontWeight: 700,
			'&:after': {
				display: 'none',
			},
			'& br': {
				display: 'none',
			}
		},
		[theme.breakpoints.down(361)]: {
			paddingLeft: 0,
			paddingRight: 0,
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: 36,
			lineHeight: '36px',
		}
	},
	description: {
		fontSize: 28,
		fontWeight: 400,
		letterSpacing: '0.5px',
		lineHeight: '35px',
		width: '100%',
		'& span': {
			fontWeight: 700,
		},
		[theme.breakpoints.down('md')]: {
			fontSize: 24,
			fontWeight: 400,
			letterSpacing: '0.5px',
			lineHeight: '27px',
			textAlign: 'center',
			'& span': {
				fontWeight: 400,
			},
		}
	},
	subtitle: {
		fontSize: 24,
		fontWeight: 700,
		color: theme.palette.primary.main,
		letterSpacing: '0.18px',
	},
	divider: {
		'&:after': {
			content: '""',
			display: 'block',
			width: '67px',
			height: 4,
			backgroundColor: theme.palette.secondary.main,
		},
	},
	listItem: {
		'& span': {
			fontSize: 20
		}
	},
	simulatorContainer: {
		padding: '48px 0px',
		[theme.breakpoints.down('md')]: {
			padding: '40px 0px',
		}
	},
	simulator: {
		'& svg g': {
			fill: '#B6B6B6'
		},
		'& > div': {
			marginLeft: 'auto',
			minHeight: 'auto !important',
			width: 385,
			[theme.breakpoints.down('md')]: {
				width: '100%',
			}
		},
		'&.multiProduct': {
			'& > div': {
				minHeight: '493.38px!important',
			}
		},
	},
	simulatorLoader: {
		marginLeft: 'auto',
		width: 353,
		[theme.breakpoints.down('md')]: {
			width: '100%',
		}
	},
	tertiary: {
		color: theme.palette.tertiary.main,
	},
	item: {
		[theme.breakpoints.down('md')]: {
			padding: 0,
		}
	}
}))