import AgeIcon from "./assets/age.svg"
import EmploymentIcon from "./assets/employment.svg"
import HistoryIcon from "./assets/history.svg"
import IncomeIcon from "./assets/income.svg"

export const requirementsItems: {
    icon: any
    title: string
    description: string
}[] = [
        {
            icon: AgeIcon,
            title: 'Edad mínima 25 años',
            description: 'Y edad máxima 64 años 11 meses'
        },
        {
            icon: EmploymentIcon,
            title: 'Buen historial',
            description: 'Tener un buen historial crediticio'
        },
        {
            icon: HistoryIcon,
            title: 'Continuidad laboral ',
            description: 'Mínimo 12 meses en el empleo actual '
        },
        {
            icon: IncomeIcon,
            title: 'Ingreso mínimo mensual',
            description: 'Desde $8,000 pesos y puedes sumar ingresos con un coacreditado'
        },
    ]